import React, { ReactElement, useState } from "react";
import IconLinkBox from "src/components/IconLinkBox";
import {
  AttendanceRoutes,
  EmployeeAutoComplete,
  HRRecordRoutes,
  InitEmployeeAutoComplete,
  InterviewRecordRoutes,
  OrganizationRoutes,
  TodayAttendanceRoutes,
} from "src/system/types";
import TextsmsIcon from "@material-ui/icons/Textsms";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PeopleIcon from "@material-ui/icons/People";
import BallotIcon from "@material-ui/icons/Ballot";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import PrintIcon from "@material-ui/icons/Print";
import { Grid, Paper, Theme } from "@material-ui/core";
import PersonalAttendance from "./attendance/PersonalAttendance";
import AnnualLeave from "./attendance/AnnualLeave";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import SettingsIcon from "@material-ui/icons/Settings";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useUserState } from "src/system/UserContext";
import MyLateExplanation from "./attendance/MyLateExplanation";
import SearchBarView from "./card/SearchBarView";
import PublicIcon from "@material-ui/icons/Public";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const LAYOUT_TEST = "ghostwhite";

const MyStyle = makeStyles((theme: Theme) =>
  createStyles({
    paperLinkBox: {
      float: "left",
      width: "130px",
      height: "130px",
      borderRadius: "20px",
      backgroundColor: "#f1f1f1",
      margin: "10px",
      textAlign: "center",
      "& div:hover": {
        boxShadow: "gray 0px 0px 0px",
      },
      cursor: "pointer",
    },
    paperMainLinkList: {
      display: "inline-block",
      width: "1200px",
      margin: "5px",
      padding: "10px",
      overflow: "hidden",
      backgroundColor: LAYOUT_TEST,
      "& .MuiPaper-root:hover": {
        cursor: "pointer",
        boxShadow: "gray 3px 3px 3px",
      },
    },
    divMainPage: {
      // textAlign: "center",
    },
    paperMain: {
      position: "relative",
      display: "inline-block",
      width: "1120px",
      margin: "5px;",
      padding: "10px",
      overflow: "hidden",
      backgroundColor: LAYOUT_TEST,
    },
  })
);

interface MainPageProps {}

// eslint-disable-next-line no-empty-pattern
function MainPage({}: MainPageProps): ReactElement {
  const classes = MyStyle();
  const user = useUserState();
  const admin = user.role === "admin";
  const [employeeNo, setEmployeeNo] = useState(user.employeeId);
  const [employee, setEmployee] = useState<EmployeeAutoComplete>({
    ...InitEmployeeAutoComplete,
    employeeId: user.employeeId,
    employeeNo: user.employeeNo,
    workplace: user.workplace,
  });

  const onChangeSelect = (
    event: React.ChangeEvent<{}>,
    value: EmployeeAutoComplete | null
  ) => {
    if (value !== null) {
      setEmployeeNo(value.employeeNo);
      setEmployee(value);
    }
  };

  return (
    <div className={classes.divMainPage}>
      <Grid container>
        {false && (
          <Grid item xs={12}>
            {" "}
            <Paper className={classes.paperMainLinkList}>
              {admin && (
                <>
                  {/* 관리자 메뉴 */}
                  <IconLinkBox
                    title={"관리자"}
                    className={classes.paperLinkBox}
                    icon={<SettingsIcon></SettingsIcon>}
                    path={HRRecordRoutes.employee}
                  ></IconLinkBox>
                  <IconLinkBox
                    title={"인사등록"}
                    className={classes.paperLinkBox}
                    icon={<PersonAddIcon></PersonAddIcon>}
                    path={HRRecordRoutes.employeeAdd}
                  ></IconLinkBox>
                  {/* 관리자 + 파트장/팀장 메뉴 */}
                  <IconLinkBox
                    title={"근태관리"}
                    className={classes.paperLinkBox}
                    icon={<EventAvailableIcon></EventAvailableIcon>}
                    path={AttendanceRoutes.admin}
                  ></IconLinkBox>
                  <IconLinkBox
                    title={"면담록"}
                    className={classes.paperLinkBox}
                    icon={<TextsmsIcon></TextsmsIcon>}
                    path={InterviewRecordRoutes.root}
                  ></IconLinkBox>
                </>
              )}
            </Paper>
            <Paper className={classes.paperMainLinkList}>
              {/* 전체 메뉴 */}
              <IconLinkBox
                title={"인사카드"}
                className={classes.paperLinkBox}
                path={HRRecordRoutes.card}
                icon={<FolderSharedIcon></FolderSharedIcon>}
              ></IconLinkBox>
              <IconLinkBox
                title={"조직도"}
                className={classes.paperLinkBox}
                icon={<PeopleIcon></PeopleIcon>}
                path={OrganizationRoutes.root}
              ></IconLinkBox>
              <IconLinkBox
                title={"통합근태"}
                className={classes.paperLinkBox}
                icon={<CalendarTodayIcon></CalendarTodayIcon>}
                path={AttendanceRoutes.root}
              ></IconLinkBox>
              <IconLinkBox
                title={"오늘의 근태"}
                className={classes.paperLinkBox}
                icon={<RecentActorsIcon></RecentActorsIcon>}
                path={TodayAttendanceRoutes.root}
              ></IconLinkBox>
              {/* <IconLinkBox
              title={"InBody Portal"}
              className={classes.paperLinkBox}
              icon={<PublicIcon></PublicIcon>}
              path={"https://weareinbody.com/"}
            ></IconLinkBox>
            <IconLinkBox
              title={"사업계획"}
              className={classes.paperLinkBox}
              icon={<BallotIcon></BallotIcon>}
              path={"https://plan.weareinbody.com/"}
            ></IconLinkBox>
            <IconLinkBox
              title={"다면평가"}
              className={classes.paperLinkBox}
              icon={<RecentActorsIcon></RecentActorsIcon>}
              path={"https://360degree.weareinbody.com/"}
            ></IconLinkBox>
            <IconLinkBox
              title={"회사생활 가이드"}
              className={classes.paperLinkBox}
              icon={<LiveHelpIcon></LiveHelpIcon>}
              path={"https://inbodykr.sharepoint.com/sites/InBodyQNA"}
            ></IconLinkBox> */}
              {admin && (
                <>
                  {" "}
                  <IconLinkBox
                    title={"증빙서류 발급"}
                    className={classes.paperLinkBox}
                    icon={<PrintIcon></PrintIcon>}
                    path={HRRecordRoutes.evidence}
                  ></IconLinkBox>
                </>
              )}
            </Paper>
          </Grid>
        )}

        <Grid item xs={12}>
          {" "}
          {/* 근태 관리표 */}
          <Paper className={classes.paperMain}>
            {(admin ||
              user.jobTitle.includes("팀장") ||
              user.jobTitle.includes("파트장")) && (
              <SearchBarView onChangeSelect={onChangeSelect}></SearchBarView>
            )}
            <PersonalAttendance
              employee={employee}
              isAdmin={admin}
            ></PersonalAttendance>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {/* 지각 소명 신청 내역 */}
          <Paper className={classes.paperMain}>
            <MyLateExplanation employeeNo={employeeNo}></MyLateExplanation>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default MainPage;
