import { Dialog, DialogContent, makeStyles, Grid, Typography, Table, DialogTitle, TextField } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { Attendance, EmployeeAutoComplete, InitLateExplanation, LateData, ObjType } from "src/system/types";
import ButtonBlue from "./ButtonBlue";
import ButtonRed from "./ButtonRed";
import CloseIcon from "@material-ui/icons/Close";
import { AttendanceApi, ErrorHandler } from "src/system/ApiService";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useUserState } from "src/system/UserContext";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface LateModalProps {
  open: boolean;
  data: Attendance;
  employee: EmployeeAutoComplete;
  completeLate: (complete: boolean) => void;
  onClose: () => void;
}

function LateModal({ open, data, employee, completeLate, onClose }: LateModalProps): ReactElement {
  const classes = useStyles();
  const [late, setLate] = useState<LateData>({ ...InitLateExplanation, employeeName: employee.name });
  const [file, setFile] = useState<File>();
  const status = late.state;
  const check = late.content.length >= 1 && late.kind ? false : true;
  const user = useUserState();

  const onLoad = React.useCallback(() => {
    AttendanceApi.GetLateExplanation(employee.employeeNo, data.date)
      .then((res) => {
        setLate({ ...res });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, [employee, data.date]);

  useEffect(() => {
    onLoad();
    if (status === "승인완료") {
      completeLate(true);
    } else completeLate(false);
  }, [onLoad, employee, completeLate, status]);

  const onclick = () => {
    const formData = new FormData();
    if (late) {
      if (file) {
        formData.append("FileLate", file);
      }
      const obj: ObjType = { ...late, state: "신청완료", applicantId: user.employeeNo };
      for (const key of Object.keys(late)) {
        const value = obj[key];
        if (value === undefined) continue;
        else {
          formData.append(key, value);
        }
      }

      AttendanceApi.UploadLateExplanation(formData)
        .then(() => {
          alert("지각 소명 신청이 완료되었습니다.");
          onClose();
          onLoad();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
  };

  const cancelClick = () => {
    if (late) {
      AttendanceApi.DeleteLateExplanation(late.id)
        .then(() => {
          alert("지각소명 신청이 취소되었습니다.");
          onLoad();
          onClose();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setFile(event.target.files[0]);
      setLate({
        ...late,
        fileName: event.target.files[0]?.name,
        fileSize: event.target.files[0]?.size,
      });
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setLate({
      ...late,
      content: value,
    });
  };

  const onChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLate({
      ...late,
      kind: event.target.value as string,
    });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle style={{ backgroundColor: "#1769aa", color: "white" }}>
        <Typography style={{ fontWeight: "bold" }}>지각소명 신청</Typography>
        <CloseIcon className={classes.closeButton} onClick={onClose} />
      </DialogTitle>
      <Grid spacing={1}>
        <DialogContent>
          <div style={{ fontWeight: "bold" }}>지각소명 신청 내역</div>
          <br />
          <Table className="text-center">
            <thead style={{ backgroundColor: "#616161", color: "white" }}>
              <tr>
                <th style={{ width: "10%" }}>일자</th>
                <th style={{ width: "10%" }}>소명 대상</th>
                <th style={{ width: "15%" }}>사유</th>
                <th style={{ width: "30%" }}>소명 내역</th>
                <th style={{ width: "10%" }}>진행 상태</th>
                <th>파일 첨부</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.date.toLocaleDateString()}</td>
                <td>{status === "없음" ? (employee.name === "" ? user.name : employee.name) : late.employeeName}</td>
                <td>
                  {status === "없음" ? (
                    <FormControl className={classes.formControl}>
                      <Select value={late.kind} onChange={onChangeSelect}>
                        <MenuItem value={"열차지연"}>열차지연</MenuItem>
                        <MenuItem value={"지문문제"}>지문 문제</MenuItem>
                        <MenuItem value={"기타"}>기타</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    late.kind
                  )}
                </td>
                <td>
                  {status === "없음" ? (
                    <TextField multiline style={{ width: "80%" }} onChange={onChange}></TextField>
                  ) : (
                    late.content
                  )}
                </td>
                <td>
                  {/* 승인 상태에 따라서 진행 상태 다름 */}
                  {status && status === "신청완료" ? (
                    <div style={{ fontWeight: "bold" }}>
                      신청 완료
                      <ButtonRed onClick={cancelClick}>신청 취소</ButtonRed>
                    </div>
                  ) : (
                    <div style={{ fontWeight: "bold" }}>{status}</div>
                  )}
                </td>
                <td>
                  {status && status === "없음" ? (
                    <input onChange={onChangeFile} type="file" style={{ width: "90%" }}></input>
                  ) : (
                    <div>
                      {late.fileName !== "null" && late.fileName ? (
                        <a href={`/lates/${late.fileName}`} rel="noreferrer" target="_blank">
                          파일보기
                        </a>
                      ) : (
                        "-"
                      )}
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          <br />
          <div>
            <div>*주의 사항</div>
            <div>-부주의(ex.출입 시 지문 미태그 등)로 인한 지각의 경우 소명 불가합니다.</div>
            <div>-열차 지연으로 인한 지각의 경우 열차지연증명서 파일 첨부 부탁드립니다.</div>
          </div>
          <br />
          {status === "없음" ? (
            <div className="text-center">
              <ButtonBlue disabled={check} onClick={onclick}>
                확인
              </ButtonBlue>
            </div>
          ) : (
            <div className="text-center">
              <ButtonBlue
                onClick={() => {
                  onClose();
                }}
              >
                확인
              </ButtonBlue>
            </div>
          )}
          <br />
        </DialogContent>
      </Grid>
    </Dialog>
  );
}
export default LateModal;
