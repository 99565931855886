import {
  Employee,
  DetailCare,
  Aftercare,
  Interview,
  ViewPageData,
  AuthorityUser,
  Issue,
  Attendance,
  Groupware,
  AttendanceStatics,
  QNA,
  MonthlyAttendanceStatics,
  PersonalData,
  Recruitment,
  Career,
  TextFieldError,
  ValidationPD,
  EducationSchool,
  ValidationES,
  FamilyMember,
  ValidationFM,
  Certificate,
  Language,
  ValidationCA,
  Applicant,
  LateData,
  Evidence,
  EvidenceContent,
  CustomTreeView,
} from "src/system/types/interface";
import { DeptTreeView, EmployeeAutoComplete } from ".";
import { SearchResult } from "./class";

export const initEmployee: Employee = {
  employeeId: "",
  name: "",
  team: "",
};
export const initDetailCare: DetailCare = {
  id: 0,
  target: "",
  result: "",
};

export const initAftercare: Aftercare = {
  id: 0,
  taskMaster: initEmployee,
  content: "",
  created: new Date().getTime(),
  completed: undefined,
  estimated: new Date().getTime(),
  detailcares: [],
};

export const initInterview: Interview = {
  id: 0,
  categoryId: 0,
  interviewer: initEmployee,
  interviewee: initEmployee,
  writer: initEmployee,
  content: "",
  created: new Date().getTime(),
  occurred: new Date().getTime(),
  accessModifier: "",
  accessModifierId: 1,
  accessReader: [],
  category: "",
};

export const initViewPage: ViewPageData = {
  interview: initInterview,
  aftercares: [],
  file: null,
  issue: null,
};

export const InitUser: AuthorityUser = {
  employeeId: "",
  employeeNo: "",
  name: "",
  gwName: "",
  gwMail: "",
  jobTitle: "",
  jobPosition: "",
  workplace: "",
  group: "",
  groupId: 0,
  teamId: 0,
  teamName: "",
  parentTeamId: 0,
  parentTeamName: "",
  role: "",
  positionCode: "",
  authority: [],
  scheduleStartTime: "",
  joined: "",
};

export const InitIssue: Issue = {
  id: 0,
  category: "",
  categoryId: 0,
  content: "",
  accessModifierId: 1,
  accessModifier: "",
  writer: initEmployee,
  accessReader: [],
  concerneds: [],
  created: new Date().getTime(),
  occurred: new Date().getTime(),
};

export const InitQNA: QNA = {
  id: 0,
  categoryId: 0,
  category: "",
  title: "",
  requester: "",
  status: "",
  created: new Date().getTime(),
  isOpen: false,
  answerer: "",
  answer: "",
  content: "",
  fileQ: "",
  fileA: "",
  fileQSize: 0,
  fileASize: 0,
  formFileQ: new FormData(),
  formFileA: new FormData(),
};

export const InitAttendance: Attendance = {
  date: new Date(),
  day: 0,
  dayOfTheWeek: "",
  isBlur: false,
  holiday: "",
  start: "",
  end: "",
  bLate: false,
  cLate: false,
  eLate: false,
  owTime: 0,
  category: "",
  gwStart: "",
  gwEnd: "",
  gwStatus: "",
  gwWorkTime: 0,
  gwOWTime: 0,
  gwCancelStatus: 0,
};

export const InitAttendanceStatics: AttendanceStatics = {
  month: 0,
  secomWorkTime: 0,
  totalLate: 0,
  totalLateTime: 0,
  totalEarlyTime: 0,
  totalNightShift: 0,
  totalNightShiftTime: 0,
  totalOutsideWork: 0,
  totalOutsideWorkTime: 0,
  totalTripWork: 0,
  totalTripWorkTime: 0,
  publicHolidayWork: 0,
  publicHolidayWorkTime: 0,
  privateHolidayWork: 0,
  privateHolidayWorkTime: 0,
};

export const InitLateExplanation: LateData = {
  id: 0,
  date: "",
  employeeId: "",
  employeeName: "",
  applicantId: "",
  applicantName: "",
  kind: "",
  content: "",
  state: "없음",
  fileName: "",
  fileSize: 0,
  reject: "",
  created: new Date(),
  updated: new Date(),
};

export const InitMonthlyAttendanceStatics: MonthlyAttendanceStatics = {
  absent: 0,
  late: 0,
  lateExp: 0,
  monthlyLate: 0,
  yearlyLate: 0,
  yearlyLateExp: 0,
  yearOfService: 0,
  totalLeave: 0,
  monthlyLeave: 0,
  annualLeave: 0,
  usedAnnualLeave: 0,
  nightShiftSecom: 0,
  nightShiftV4: 0,
  monthlyNightShift: 0,
};

export const InitGroupware: Groupware = {
  date: new Date(),
  team: "",
  kind: "",
  category: "",
  name: "",
  state: "",
  term: 0,
  startTime: new Date(),
  endTime: new Date(),
};

export function GetInitSearchResult<T>(ary: T[]): SearchResult<T> {
  return {
    list: ary,
    page: 1,
    count: 0,
    totalPage: 1,
  };
}

export const InitPersonalData: PersonalData = {
  id: 0,
  employeeId: "",
  employeeNo: "",
  name: "",
  enName: "",
  gwName: "",
  email: "",
  gwMail: "",
  gender: "",
  birthday: "",
  phone: "",
  tel: "",
  nationality: "",
  zip: "",
  address1: "",
  address2: "",
  residenceNumber: "",
  researcherNumber: "",
  lastModified: "",
  isMSAccountCreate: true,
  workplace: "",
  team: "",
  teamId: "",
  department: "",
  departmentId: "",
  group: "",
  groupId: "",
  inputTeam: null,
  efCategory: null,
  wsCategory: null,
  workStatus: "",
  jobGroup: "",
  jobDuty: "",
  jobPosition: "",
  jobPositionRank: "",
  jobTitle: "",
  joined: "",
  ended: "",
  employmentForm: "",
  workRecordLastModified: "",
  serviceStatus: "",
  serviceType: "",
  serviceSpeciality: "",
  serviceRank: "",
  serviceStarted: "",
  serviceEnded: "",
  serviceDischargeCategory: "",
  serviceRemark: "",
  serviceLastModified: "",
  isVeterans: false,
  veteransNumber: "",
  veteransType: "",
  veteransRelation: "",
  veteransRatio: "",
  veteransRemark: "",
  veteransLastModified: "",
  isDisability: false,
  disabilityRank: "",
  disabilityContent: "",
  disabilityRemark: "",
  disabilityOnsetDate: "",
  disabilityLastModified: "",
  isLast: false,
  approvalId: 0,
  bank: "",
  number: "",
  holder: "",
  accountLastModified: "",
  applyChannel: "",
};

export const InitRecruitPersonalData: PersonalData = {
  ...InitPersonalData,
  gender: "M",
  workStatus: "입사",
  nationality: "대한민국",
  gwMail: "@inbody.com",
};

export const InitRecruitment: Recruitment = {
  rcode: "",
  subject: "",
  start_date: new Date(),
  end_date: new Date(),
};

export const InitCareer: Career = {
  id: 0,
  employeeId: "",
  employmentForm: "",
  company: "",
  started: "",
  ended: "",
  department: "",
  jobPosition: "",
  work: "",
  salary: "",
  jobDuty: "",
  retirementReason: "",
  created: new Date(),
  updated: new Date(),
  approvalId: 0,
  remark: "",
};

export const InitTextFieldError: TextFieldError = {
  name: { isError: true, message: "필수 입력사항입니다." },
  enName: { isError: true, message: "필수 입력사항입니다." },
  employeeId: {
    isError: true,
    message: "사번 중복체크를 해주세요.",
  },
  nationality: { isError: true, message: "필수 입력사항입니다." },
  birthday: { isError: true, message: "필수 입력사항입니다." },
  residenceNumber: { isError: true, message: "필수 입력사항입니다." },
  phone: { isError: true, message: "필수 입력사항입니다." },
  email: { isError: true, message: "필수 입력사항입니다." },
  gwMail: {
    isError: true,
    message: "이메일 중복체크를 해주세요.",
  },
  address1: { isError: true, message: "필수 입력사항입니다." },
  zip: { isError: false, message: "" },
  joined: { isError: true, message: "필수 입력사항입니다." },
  wsCategory: { isError: true, message: "필수 입력사항입니다." },
  efCategory: { isError: true, message: "필수 입력사항입니다." },
  workplace: { isError: true, message: "필수 입력사항입니다." },
  jobGroup: { isError: true, message: "필수 입력사항입니다." },
  jobDuty: { isError: true, message: "필수 입력사항입니다." },
  jobPosition: { isError: true, message: "필수 입력사항입니다." },
  jobPositionRank: { isError: true, message: "필수 입력사항입니다." },
  jobTitle: { isError: true, message: "필수 입력사항입니다." },
  serviceStatus: { isError: true, message: "필수 입력사항입니다." },
  bank: { isError: true, message: "필수 입력사항입니다." },
  number: { isError: true, message: "필수 입력사항입니다." },
  holder: { isError: true, message: "필수 입력사항입니다." },
};

export const InitValidationPD: ValidationPD = {
  mss: [],
  mst: [],
  msr: [],
  msd: [],
  dr: [],
  dc: [],
  vt: [],
  vr: [],
  vrt: [],
  wef: [],
  wws: [],
  wwp: [],
  wjg: [],
  wjd: [],
  wjp: [],
  wjpr: [],
  wjt: [],
  // deptInfoErp: [],
};

export const InitEducationSchool: EducationSchool = {
  id: 0,
  employeeId: "",
  isFinal: false,
  kind: "",
  name: "",
  started: "",
  ended: "",
  graduateStatus: "",
  degree: "",
  isMain: "",
  gpa: "",
  scale: "",
  major: "",
  minor: "",
  location: "",
  doubleMajor: "",
  doubleDegree: "",
  remark: "",
};

export const InitValidationES: ValidationES = {
  kind: [],
  gs: [],
  ismain: [],
  scale: [],
};

export const InitValidationFM: ValidationFM = {
  relation: [],
};

export const InitValidationCE: ValidationCA = {
  employmentForm: [],
};

export const InitFamilyMember: FamilyMember = {
  id: 0,
  employeeId: "",
  //gender: "F" | "M";
  relation: "",
  name: "",
  birthday: "",
  //age: number;
  isDisability: false,
  isForeign: false,
  remark: "",
  tel: "",
  telNo: 0,
  updated: "",
};

export const InitCertificate: Certificate = {
  id: 0,
  employeeId: "",
  certification: "",
  agency: "",
  registrationNo: "",
  taked: "",
  expired: "",
  evidence: "",
  file: undefined,
  fileName: "",
  fileId: 0,
  remark: "",
};

export const InitLanguage: Language = {
  id: 0,
  employeeId: "",
  certification: "",
  registrationNo: "",
  tested: "",
  score: "",
  maxScore: "",
  evidence: "",
  file: undefined,
  fileName: "",
  fileId: 0,
  remark: "",
};

export const InitApplicant: Applicant = {
  index: 0,
  lcode: "",
  subject: "",
  email: "",
  name: "",
  division: "",
  division2: "",
  region: "",
  gender: "",
  rcode: "",
  ano: 0,
  enrollment: false,
};

export const InitEmployeeAutoComplete: EmployeeAutoComplete = {
  employeeNo: "",
  employeeId: "",
  name: "",
  email: "",
  workplace: "",
  groupId: "",
  group: "",
  departmentId: "",
  department: "",
  teamId: "",
  team: "",
  jobPosition: "",
  jobTitle: "",
  phone: "",
  tel: "",
  gwName: "",
  joined: "",
};

export const InitDeptTreeView: DeptTreeView = {
  id: "root",
  name: "(주)인바디",
  subChild: [],
};

export const InitEvidence: Evidence = {
  id: 0,
  employeeId: "",
  name: "",
  department: "",
  team: "",
  type: "",
  usage: "",
  status: "승인대기",
  requested: "",
  approved: "",
  approverName: "",
  approverId: "",
};

export const InitEvidenceContent: EvidenceContent = {
  employeeId: "",
  name: "",
  address: "",
  residenceNumber: "",
  team: "",
  jobPosition: "",
  term: "",
  usage: "",
  no: "",
};
