import React, { ReactElement, useEffect, useState } from "react";
import ButtonRed from "src/components/ButtonRed";
import ButtonBlue from "src/components/ButtonBlue";
import RejectModal from "src/components/RejectModal";
import { useUserState } from "src/system/UserContext";
import { AttendanceApi, ErrorHandler } from "src/system/ApiService";
import { InitLateExplanation, LateData, ObjType } from "src/system/types";
import MaterialTable from "material-table";
import { MyStyle } from "src/Style/theme";
import { TABLEICONS, TABLE_HEADER } from "src/system/Constants";

function LateExplanationList(): ReactElement {
  const classes = MyStyle();
  const user = useUserState();
  const [data, setData] = useState<LateData[]>();
  const [open, setOpen] = useState(false);
  const [unitData, setUnitData] = useState<LateData>(InitLateExplanation);
  const kindLookup: Object = {
    열차지연: "열차지연",
    지문문제: "지문 문제",
    기타: "기타",
  };
  const stateLookup: Object = {
    신청완료: "신청완료",
    승인완료: "승인완료",
    반려: "반려",
  };

  const onLoad = React.useCallback(() => {
    AttendanceApi.GetLateExplanationList(
      user.employeeId,
      "ALL_LATE_EXPLANATION"
    )
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        console.log(msg);
      });
  }, [user.employeeId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const openModal = (rowData: LateData) => {
    if (rowData !== undefined) {
      setUnitData(rowData);
      setOpen(true);
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  const permitClick = (rowData: LateData) => {
    const formData = new FormData();
    const obj: ObjType = {
      ...rowData,
      state: "승인완료",
      name: rowData.employeeName,
      approverId: user.employeeId,
    };
    for (const key of Object.keys(rowData)) {
      const value = obj[key];
      if (value === undefined) continue;
      else {
        formData.append(key, value);
      }
    }
    AttendanceApi.UploadLateExplanation(formData)
      .then(() => {
        alert("승인되었습니다.");
        onLoad();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  };

  return (
    <div className={classes.paperMainTitle}>
      <MaterialTable
        icons={TABLEICONS}
        title="지각소명 신청 내역"
        data={data || []}
        columns={[
          { title: "날짜", field: "date", cellStyle: { width: "10%" } },
          {
            title: "소명대상",
            field: "employeeName",
            cellStyle: { width: "8%" },
          },
          {
            title: "신청자",
            field: "applicantName",
            cellStyle: { width: "8%" },
          },
          {
            title: "사유",
            field: "kind",
            cellStyle: { width: "10%" },
            lookup: kindLookup,
          },
          { title: "소명 내용", field: "content", cellStyle: { width: "30%" } },
          {
            title: "결재 진행",
            field: "",
            cellStyle: { width: "14%" },
            render: (rowData) => {
              return (
                <>
                  {rowData.state === "승인완료" || rowData.state === "반려" ? (
                    "-"
                  ) : (
                    <div>
                      <ButtonBlue
                        onClick={() => permitClick(rowData)}
                        style={{ color: "white", padding: "3px 10px" }}
                      >
                        승인
                      </ButtonBlue>
                      <ButtonRed
                        onClick={() => openModal(rowData)}
                        style={{ marginLeft: "3px" }}
                      >
                        반려
                      </ButtonRed>
                    </div>
                  )}
                </>
              );
            },
          },
          {
            title: "진행 상태",
            field: "state",
            width: 40,
            cellStyle: { width: "10%" },
            lookup: stateLookup,
          },
          {
            title: "파일",
            field: "fileName",
            cellStyle: { width: "10%" },
            render: (rowData) => {
              return rowData.fileName !== "null" && rowData.fileName ? (
                <a
                  href={`/lates/${rowData.fileName}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  파일보기
                </a>
              ) : (
                "-"
              );
            },
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: "표시할 데이터가 없습니다.",
          },
        }}
        options={{
          search: true,
          pageSize: 10,
          filtering: true,
          padding: "dense",
          headerStyle: TABLE_HEADER,
        }}
      ></MaterialTable>
      <RejectModal
        open={open}
        late={unitData}
        onLoad={onLoad}
        onClose={closeModal}
      ></RejectModal>
    </div>
  );
}

export default LateExplanationList;
