import React, { MouseEvent, ReactElement, useEffect, useState } from "react";
import { Typography, Chip } from "@material-ui/core";
import { Attendance, EmployeeAutoComplete } from "src/system/types";
import ChipCalendar from "./ChipCalendar";
import LateExplanationModal from "src/components/LateExplanationModal";
import { useUserState } from "src/system/UserContext";

interface CellDayProps {
  data: Attendance;
  employee: EmployeeAutoComplete;
  clickCellDay: (date: Date) => void;
}

function CellDay({ data, employee, clickCellDay }: CellDayProps): ReactElement {
  useEffect(() => {}, [data]);
  const [openLateModal, setOpenLateModal] = React.useState(false);
  const [checkLate, setCheckLate] = useState<Boolean>(false);
  const now = new Date();
  const weekago = new Date(data.date);
  weekago.setDate(data.date.getDate() + 7);
  const check = data.date <= now && now <= weekago ? false : true;
  const user = useUserState();

  const completeLate = React.useCallback((complete: boolean) => {
    setCheckLate(complete);
  }, []);

  const clickOpenLateModal = () => {
    setOpenLateModal(true);
  };
  const clickCloseLateModal = () => {
    setOpenLateModal(false);
  };

  const onClickCellDay = (event: any) => {
    clickCellDay(data.date);
  };

  // 무단결근 여부
  const isAbsent =
    data.start === null &&
    data.end === null &&
    data.category === null &&
    !data.cLate &&
    (data.holiday.includes("평일") || data.holiday.includes("근무"));

  // 지각 여부
  const isLate =
    ((data.bLate && !data.cLate && !data.eLate) || // 지각(bLate)이면서 통합근태 지각 취소사유(cLate)가 없을 떄
      (data.start === null && data.end !== null && !data.cLate)) && // 출근안찍고 퇴근찍었는데 통합근태 지각 취소사유(cLate)없을 떄
    (data.holiday.includes("평일") || data.holiday.includes("근무"));

  // 임원 여부
  const isExecutive = [
    "이사",
    "이사회구성원(내부결재外)",
    "고문",
    "사장",
    "부사장",
    "회장",
    "상무이사",
    "전무",
  ].includes(employee.jobPosition);

  return (
    <>
      {data.isBlur ? (
        <td style={{ padding: "0px", color: "#bfbfbf" }}>
          <div style={{ height: "100px" }}>{data.day}</div>
        </td>
      ) : (
        <td style={{ padding: "0px" }}>
          <div
            style={{ height: "110px", cursor: "pointer", zIndex: 0 }}
            onClick={onClickCellDay}
          >
            {/* 날짜 */}
            {data.dayOfTheWeek === "토" ? (
              <Typography variant="body2" style={{ color: "blue" }}>
                {data.day}
              </Typography>
            ) : data.dayOfTheWeek === "일" ||
              (!(
                data.holiday.includes("평일") || data.holiday.includes("근무")
              ) &&
                data.holiday !== "") ? (
              <Typography variant="body2" style={{ color: "red" }}>
                {`${data.day} ${data.holiday !== "휴일" ? data.holiday : ""}`}
              </Typography>
            ) : (
              <Typography variant="body2" style={{ color: "black" }}>
                {data.day}
              </Typography>
            )}

            {/* 근무 시간 */}
            <div
              style={{
                textAlign: "center",
                color: "dimgray",
                minHeight: "24px",
              }}
            >
              {/* 출근이나 퇴근 기록 중 하나만 있는 경우 */}
              {(data.start || data.end) &&
                (data.start ? (
                  data.start
                ) : (
                  <span style={{ color: "red" }}>X</span>
                ))}
              {(data.start || data.end) && " ~ "}
              {(data.start || data.end) &&
                (data.end ? data.end : <span style={{ color: "red" }}>X</span>)}
            </div>
            {/* 무단결근 : 출퇴근 기록이 없는데 통합근태 신청서도 없는 경우 */}
            {isAbsent && !isExecutive && (
              <div>
                <div
                  onClick={(event: MouseEvent) => {
                    event.stopPropagation();
                  }}
                >
                  <Chip
                    size="small"
                    label={checkLate ? "지각소명" : "무단결근"}
                    onClick={clickOpenLateModal}
                    disabled={user.role !== "admin"}
                    color="secondary"
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      marginLeft: "10px",
                      boxShadow: "#999999 1px 1px 1px",
                      zIndex: 1,
                    }}
                  ></Chip>
                  <LateExplanationModal
                    open={openLateModal}
                    data={data}
                    employee={employee}
                    onClose={clickCloseLateModal}
                    completeLate={completeLate}
                  ></LateExplanationModal>
                </div>
              </div>
            )}
            {/* 지각 */}
            {isLate && !isExecutive && (
              <div>
                <div
                  onClick={(event: MouseEvent) => {
                    event.stopPropagation();
                  }}
                >
                  <Chip
                    size="small"
                    label={checkLate ? "지각 소명" : "지각"}
                    onClick={clickOpenLateModal}
                    disabled={check}
                    color="secondary"
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      marginLeft: "10px",
                      boxShadow: "#999999 1px 1px 1px",
                    }}
                  ></Chip>
                  <LateExplanationModal
                    open={openLateModal}
                    data={data}
                    employee={employee}
                    onClose={clickCloseLateModal}
                    completeLate={completeLate}
                  ></LateExplanationModal>
                </div>
              </div>
            )}
            {/* 야근 */}
            {data.owTime >= 120 && data.holiday.includes("평일") && (
              <ChipCalendar label="야근식대"></ChipCalendar>
            )}
            {/* 외근/현지출퇴근으로 야근 (39600 = 11시간 (9시간 + 야근 2시간)) */}
            {!(data.owTime >= 120 && data.holiday.includes("평일")) &&
              data.category === "외근/현지출퇴근" &&
              data.gwOWTime >= 120 && (
                <ChipCalendar label="야근식대"></ChipCalendar>
              )}
            {/* 비고(연차, 출장 등) */}
            {data.category && data.gwCancelStatus !== 3 && (
              <ChipCalendar label={data.category} data={data}></ChipCalendar>
            )}
          </div>
        </td>
      )}
    </>
  );
}

export default CellDay;
